<template>
  <VContainer
    class="pa-0"
    fluid
  >
    <VRow>
      <VCol>
        <p
          class="mb-0 mr-4 tt-text-title-2"
          data-test="complete-goal-form-title"
        >
          {{ $t('goals.title_complete') }}
        </p>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <div
          class="mb-3 tt-text-body-2"
          data-test="complete-goal-form-label-radio"
        >
          {{ $t('goals.label_radio_complete') }}
        </div>
        <VBtnToggle
          v-model="fields.state"
          dark
          mandatory
        >
          <TTBtn
            width="152"
            :value="$options.PURPOSE_STATES.COMPLETED"
            data-test-label="complete-goal-form-btn-yes"
          >
            {{ $t('items.yes') }}
          </TTBtn>
          <TTBtn
            width="152"
            :value="$options.PURPOSE_STATES.FAILED"
            data-test-label="complete-goal-form-btn-no"
          >
            {{ $t('items.no') }}
          </TTBtn>
        </VBtnToggle>
      </VCol>
      <VCol cols="12">
        <TTTextarea
          v-model="fields.comment"
          small
          :label="fields.state === $options.PURPOSE_STATES.COMPLETED ?
            $t('goals.label_input_complete_comment_yes') :
            $t('goals.label_input_complete_comment_no')"
          :placeholder="$t('goals.placeholder_input_complete_comment')"
          data-test-label="complete-goal-form-textarea-comment"
        />
      </VCol>
    </VRow>
    <div class="d-flex justify-end pt-4">
      <TTBtn
        large
        color="tt-secondary"
        depressed
        data-test="delete-staff-form-button-cancel"
        :disabled="saving"
        @click="handleClose"
      >
        {{ $t('cancel') }}
      </TTBtn>
      <TTBtn
        large
        class="white--text ml-3"
        depressed
        data-test="delete-staff-form-button-submit"
        :disabled="saving"
        :loading="saving"
        @click="handleComplete"
      >
        {{ $t('submit') }}
      </TTBtn>
    </div>
    <TTBtn
      absolute
      top
      right
      data-test="delete-staff-form-button-close"
      width="20"
      height="20"
      min-width="20"
      class="pa-0"
      depressed
      color="transparent"
      @click="handleClose"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </VContainer>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_HAPPY_STATE_STATES_ACTION } from '@/plugins/vuex/actionTypes';
import { handleError } from '@/services';
import { statePurposeUpdate } from '@/services/api/snami';
import { PURPOSE_STATES } from '@/helpers/constants';

export default {
  name: 'CompleteGoalForm',
  props: {
    goal: {
      type: Object,
      required: true,
    },
  },
  PURPOSE_STATES,
  data() {
    return {
      saving: false,
      fields: {
        state: PURPOSE_STATES.COMPLETED,
        comment: '',
      },
    };
  },
  methods: {
    ...mapActions('happyState', { setHappyStateStatesAction: SET_HAPPY_STATE_STATES_ACTION }),
    handleError,
    async handleComplete() {
      const {
        comment,
        state,
      } = this.fields;

      const data = {
        id: this.goal.id,
        comment,
        state,
      };

      try {
        this.saving = true;
        const { data: { happyState } } = await statePurposeUpdate({ data });
        this.setHappyStateStatesAction({ states: happyState });
        this.$emit('complete');
      } catch (e) {
        this.handleError(e);
      } finally {
        this.saving = false;
      }
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
