<template>
  <ItemWrapper>
    <VRow>
      <VCol cols="12">
        <h3
          data-test="goal-comment-title"
          class="tt-text-title-2"
        >
          {{ $t('goals.title_comment') }}
        </h3>
      </VCol>
    </VRow>
    <VRow class="mt-1">
      <VCol
        cols="12"
        data-test="goal-comment-desc"
        class="tt-text-body-1 text-pre-line"
      >
        {{ comment }}
      </VCol>
    </VRow>
  </ItemWrapper>
</template>
<script>
import ItemWrapper from '@/components/task/ItemWrapper.vue';

export default {
  name: 'GoalComment',
  components: {
    ItemWrapper,
  },
  props: {
    comment: {
      type: String,
      default: '',
    },
  },

};
</script>
