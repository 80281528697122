<template>
  <ItemWrapper>
    <VRow>
      <VCol cols="12">
        <h3
          data-test="goal-answer-title"
          class="tt-text-title-2"
        >
          {{ $t('goals.title_answer_actor') }}
        </h3>
      </VCol>
    </VRow>
    <VRow class="mt-1">
      <VCol
        cols="12"
        class="tt-text-body-1"
      >
        <VRow
          v-if="firstName && lastName"
          align="center"
        >
          <VCol
            cols="auto"
            class="pr-4"
          >
            <TTAvatar
              :text="firstName[0] + lastName[0]"
              data-test-label="goal-answer-avatar"
            />
          </VCol>
          <VCol
            cols="auto"
            class="tt-text-body-1 pl-0"
            data-test-label="goal-answer-fio"
          >
            {{ firstName }} {{ lastName }}
          </VCol>
        </VRow>
        <VRow class="mx-n1 mt-1">
          <VCol
            v-for="item in items"
            :key="item.id"
            class="px-1"
          >
            <TTBtn
              :class="['cursor-default']"
              large
              block
              :color="item.active ? item.activeColor : 'tt-light-mono-8'"
              :data-test-label="`goal-answer-rate-${item.id}`"
            >
              <VIcon
                :color="item.active ? 'tt-light-mono-0' : 'tt-light-mono-24'"
                :size="24"
              >
                {{ item.active ? item.activeIcon : item.noActiveIcon }}
              </VIcon>
            </TTBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            data-test="goal-answer-comment"
            class="tt-text-body-1 text-pre-line"
          >
            {{ comment }}
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </ItemWrapper>
</template>
<script>
import ItemWrapper from '@/components/task/ItemWrapper.vue';
import { SMILES } from '@/components/items/constants';

export default {
  name: 'GoalAnswer',
  components: {
    ItemWrapper,
  },
  props: {
    comment: {
      type: String,
      default: '',
    },
    rate: {
      type: Number,
      default: 0,
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
  },
  computed: {
    items() {
      const result = SMILES;
      return result.map((item) => ({
        ...item,
        active: this.rate === item.id,
      }));
    },
  },
};
</script>
<style scoped>
.cursor-default{
  cursor: default;
}
</style>
