<template>
  <div v-if="!isMobile">
    <VContainer
      v-if="!loading"
      class="pa-0"
    >
      <VRow>
        <VCol
          lg="12"
          sm="12"
        >
          <VRow>
            <VCol cols="12">
              <VRow
                dense
                align="center"
              >
                <VCol
                  cols="auto"
                >
                  <ChipStatus
                    :status="goal.state"
                    :date="goal.deadline"
                    :item-type="$options.chipStatusTypes.PURPOSE"
                    show-icon
                  />
                </VCol>
                <VCol
                  v-if="!isFinished"
                  cols="auto"
                  class="tt-light-mono-46--text tt-text-caption"
                  data-test="goal-view-deadline-date"
                >
                  <span class="text-lowercase">{{ $t('time.until') }}</span> {{ deadlineDate }}
                </VCol>
              </VRow>
              <h1
                class="tt-text-headline-1 mt-2"
                data-test="goal-view-name"
              >
                {{ goal.name }}
              </h1>
              <p
                v-if="goal.description"
                class="tt-text-body-1 mt-4 text-pre-line"
                data-test="goal-view-description"
              >
                {{ goal.description }}
              </p>
            </VCol>
          </VRow>
          <VRow v-if="!hideAllControlBtns">
            <VCol
              v-if="!isFinished"
              cols="auto"
            >
              <TTBtn
                color="tt-secondary"
                :disabled="saving"
                :loading="saving"
                data-test-label="goal-view-btn-archive"
                @click="handleArchive"
              >
                {{ $t('goals.button_archive') }}
              </TTBtn>
            </VCol>
            <VCol
              v-if="$options.PURPOSE_STATES.REJECTED === goal.state"
              cols="auto"
            >
              <TTBtn
                data-test-label="goal-view-btn-rearchive"
                :disabled="saving"
                :loading="saving"
                @click="handleRestore"
              >
                {{ $t('goals.button_return') }}
              </TTBtn>
            </VCol>
            <VCol
              v-if="!isFinished"
              cols="auto"
            >
              <TTBtn
                data-test-label="goal-view-btn-complete"
                :disabled="saving"
                @click="showCompleteForm"
              >
                {{ $t('goals.button_complete') }}
              </TTBtn>
            </VCol>
          </VRow>
          <VRow v-if="goal.comment">
            <VCol
              cols="12"
              sm="10"
            >
              <GoalComment :comment="goal.comment" />
            </VCol>
          </VRow>
          <VRow v-if="goal.actor">
            <VCol cols="10">
              <GoalAnswer
                :comment="goal.actor.comment"
                :rate="goal.actor.rate"
                :first-name="goal.actor.firstName"
                :last-name="goal.actor.lastName"
              />
            </VCol>
          </VRow>
        </VCol>
        <Portal
          to="tt-view-right"
        >
          <VContainer
            v-if="!hideAllControlBtns && !isFinished"
            class="pa-0 position-sticky--top text-center"
            fluid
          >
            <VRow justify="center">
              <VCol cols="auto">
                <TTBtn
                  fab
                  depressed
                  color="white"
                  elevation="1"
                  large
                  data-test="aside-right-location-add"
                  class="mb-2 v-btn--aside"
                  @click="showGoalForm"
                >
                  <VIcon
                    color="tt-black"
                    size="19"
                  >
                    fal fa-edit
                  </VIcon>
                </TTBtn>
                <div class="tt-text-caption tt-black--text text--lighten-2 text-nowrap">
                  {{ $t('goals.button_edit') }}
                </div>
              </VCol>
            </VRow>
          </VContainer>
        </Portal>
      </VRow>
      <DialogWrapper
        v-model="isShownGoalForm"
        fullscreen
        @click:outside="hideGoalForm"
      >
        <GoalForm
          :key="`goal-form-${goal.id}`"
          :edit="true"
          :edit-fields="goal"
          @close="hideGoalForm"
          @save="saveGoalForm"
        />
      </DialogWrapper>
      <DialogWrapper
        v-model="isShownCompleteForm"
        width="536"
        @click:outside="hideCompleteForm"
      >
        <CompleteGoalForm
          :key="`complete-goal-form-${goal.id}`"
          :goal="goal"
          @close="hideCompleteForm"
          @complete="saveCompleteForm"
        />
      </DialogWrapper>
    </VContainer>
  </div>
  <div v-else>
    <AppBarMobile>
      <VRow align="center">
        <VCol cols="1">
          <BackButton />
        </VCol>
        <VCol
          cols="10"
          class="text-center"
        >
          <h1
            data-test="goal-view-h1"
            class="tt-text-title-1 tt-light-mono-4--text"
          >
            {{ $t('goals.goals_in_work') }}
          </h1>
        </VCol>
      </VRow>
    </AppBarMobile>
    <VContainer
      v-if="!loading"
    >
      <VRow>
        <VCol cols="12">
          <GoalCardMobile :goal="goal" />
        </VCol>
      </VRow>
      <VRow v-if="!hideAllControlBtns">
        <VCol
          v-if="!isFinished"
          cols="12"
          sm="auto"
        >
          <TTBtn
            data-test-label="goal-view-btn-complete"
            :disabled="saving"
            :block="isMobile"
            @click="showCompleteForm"
          >
            {{ $t('goals.button_complete') }}
          </TTBtn>
        </VCol>
        <VCol
          v-if="!hideAllControlBtns && !isFinished"
          cols="12"
          sm="auto"
        >
          <TTBtn
            data-test-label="goal-view-btn-edit"
            :disabled="saving"
            :block="isMobile"
            @click="showGoalForm"
          >
            {{ $t('common.edit') }}
          </TTBtn>
        </VCol>
        <VCol
          v-if="!isFinished"
          cols="12"
          sm="auto"
        >
          <TTBtn
            color="tt-secondary"
            :disabled="saving"
            :loading="saving"
            :block="isMobile"
            data-test-label="goal-view-btn-archive"
            @click="handleArchive"
          >
            {{ $t('goals.button_archive') }}
          </TTBtn>
        </VCol>
        <VCol
          v-if="$options.PURPOSE_STATES.REJECTED === goal.state"
          cols="12"
          sm="auto"
        >
          <TTBtn
            data-test-label="goal-view-btn-rearchive"
            :disabled="saving"
            :block="isMobile"
            :loading="saving"
            @click="handleRestore"
          >
            {{ $t('goals.button_return') }}
          </TTBtn>
        </VCol>
      </VRow>
      <VRow v-if="goal.comment">
        <VCol
          cols="12"
          sm="10"
        >
          <GoalComment :comment="goal.comment" />
        </VCol>
      </VRow>
      <VRow v-if="goal.actor">
        <VCol cols="10">
          <GoalAnswer
            :comment="goal.actor.comment"
            :rate="goal.actor.rate"
            :first-name="goal.actor.firstName"
            :last-name="goal.actor.lastName"
          />
        </VCol>
      </VRow>
      <Portal
        to="tt-view-right"
      >
        <VContainer
          v-if="!hideAllControlBtns && !isFinished"
          class="pa-0 position-sticky--top text-center"
          fluid
        >
          <VRow justify="center">
            <VCol cols="auto">
              <TTBtn
                fab
                depressed
                color="white"
                elevation="1"
                large
                data-test="aside-right-location-add"
                class="mb-2 v-btn--aside"
                @click="showGoalForm"
              >
                <VIcon
                  color="tt-black"
                  size="19"
                >
                  fal fa-edit
                </VIcon>
              </TTBtn>
              <div class="tt-text-caption tt-black--text text--lighten-2 text-nowrap">
                {{ $t('goals.button_edit') }}
              </div>
            </VCol>
          </VRow>
        </VContainer>
      </Portal>

      <DialogWrapper
        v-model="isShownGoalForm"
        fullscreen
        @click:outside="hideGoalForm"
      >
        <GoalForm
          :key="`goal-form-${goal.id}`"
          :edit="true"
          :edit-fields="goal"
          @close="hideGoalForm"
          @save="saveGoalForm"
        />
      </DialogWrapper>
      <DialogWrapper
        v-model="isShownCompleteForm"
        width="536"
        @click:outside="hideCompleteForm"
      >
        <CompleteGoalForm
          :key="`complete-goal-form-${goal.id}`"
          :goal="goal"
          @close="hideCompleteForm"
          @complete="saveCompleteForm"
        />
      </DialogWrapper>
    </VContainer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_GOALS_ACTION, GET_TRAINEE_GOALS_ACTION } from '@/plugins/vuex/actionTypes';
import GoalForm from '@/components/goals/GoalForm.vue';
import CompleteGoalForm from '@/components/goals/CompleteGoalForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import { statePurposeUpdate } from '@/services/api/snami';
import { chipStatusTypes, PURPOSE_STATES, GOAL_TYPES } from '@/helpers/constants';
import ChipStatus from '@/components/chips/ChipStatus.vue';
import GoalComment from '@/components/goals/GoalComment.vue';
import GoalAnswer from '@/components/goals/GoalAnswer.vue';
import AppBarMobile from '@/components/app/AppBarMobile.vue';
import BackButton from '@/components/shared/BackButton.vue';
import GoalCardMobile from '@/components/cards/GoalCardMobile.vue';

export default {
  name: 'GoalView',
  components: {
    GoalCardMobile,
    BackButton,
    AppBarMobile,
    ChipStatus,
    GoalForm,
    DialogWrapper,
    CompleteGoalForm,
    GoalComment,
    GoalAnswer,
  },
  props: {
    type: {
      type: String,
      default: GOAL_TYPES.personal,
      validator: (value) => [GOAL_TYPES.personal, GOAL_TYPES.trainee].includes(value),
    },
    userId: {
      type: [String, Number],
      default: 0,
    },
    goalId: {
      type: [String, Number],
      required: true,
    },
  },
  PURPOSE_STATES,
  chipStatusTypes,
  data: () => ({
    loading: true,
    saving: false,
    isShownGoalForm: false,
    isShownCompleteForm: false,
  }),
  computed: {
    ...mapGetters('goal', ['goals', 'goalsByUserId']),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    goal() {
      if (this.type === GOAL_TYPES.trainee) {
        return this.goalsByUserId(this.userId).find((v) => +v.id === +this.goalId) || {};
      }
      return this.goals.find((v) => +v.id === +this.goalId) || {};
    },
    isFinished() {
      return [PURPOSE_STATES.REJECTED, PURPOSE_STATES.COMPLETED, PURPOSE_STATES.FAILED].includes(this.goal.state);
    },
    deadlineDate() {
      return `${this.$dayjs(this.goal.deadline).format('D MMMM YYYY')}`;
    },
    hideAllControlBtns() {
      return this.type === GOAL_TYPES.trainee;
    },
  },
  async mounted() {
    if (this.type === GOAL_TYPES.personal) {
      this.loadPersonalGoals();
    } else if (this.type === GOAL_TYPES.trainee) {
      this.loadTraineeGoals();
    }
  },
  methods: {
    ...mapActions('goal', { getGoals: GET_GOALS_ACTION, getTraineeGoals: GET_TRAINEE_GOALS_ACTION }),
    showGoalForm() {
      this.isShownGoalForm = true;
    },
    hideGoalForm() {
      this.isShownGoalForm = false;
    },
    showCompleteForm() {
      this.isShownCompleteForm = true;
    },
    hideCompleteForm() {
      this.isShownCompleteForm = false;
    },
    handleArchive() {
      this.updateState(PURPOSE_STATES.REJECTED);
    },
    handleRestore() {
      this.updateState(PURPOSE_STATES.NEW);
    },
    async loadPersonalGoals() {
      try {
        this.loading = true;
        await this.getGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async loadTraineeGoals() {
      try {
        this.loading = true;
        await this.getTraineeGoals({ userId: this.userId });
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async saveGoalForm() {
      try {
        await this.getGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.hideGoalForm();
      }
    },
    async saveCompleteForm() {
      try {
        await this.getGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.hideCompleteForm();
      }
    },
    async updateState(state) {
      try {
        this.saving = true;
        await statePurposeUpdate({
          data: {
            id: this.goal.id,
            state,
          },
        });
        await this.getGoals();
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
<style scoped>

</style>
